
/* Lists */

.list{
	/*margin-bottom:30px;*/
}


$bold: 700;
$semibold: 600;
$regular: normal;
$thin: 400;
$italic: italic;


.list-feature{
	@extend .list;
	margin:0;
	padding:0;
	width:80%;
	
	li{
		line-height:3.6em;
		@include add-border(1px, #cccccc, bottom);
		overflow:auto;
	}
	
	li:last-child{
		border:0;
	}
	
	span{
		vertical-align:top;
		padding-top:9px;
		font-size:120%;
	}
	
	i{
		margin-top:-9px;
		margin-right:5px;
	}
}

.list-comments {
	margin:0;
	padding:0;
}

.list-comments li{
	padding:10px 0 5px 0;
	@include add-border(1px, #cccccc, bottom);
	overflow:auto !important;
}

.list-comments li:last-child{
	border:0;
}

.list-comments img{
	width:100px;
}


/* Icons */

.fa-2{
	font-size:18px;
}
.fa-3{
	font-size:24px;
}
.fa-4{
	font-size:27px;
}
.fa-5{
	font-size:35px;
}
.fa-6{
	font-size:40px;
}

.fa-primary{
	color:$primary-color;
}

.fa-border{
	@include add-border(1px, $primary-color, all);
	@include border-radius(5px);
	padding:13px 15px;
	width:70px;
	margin-bottom:10px;
}


/* Headings */

.heading-primary{
	color:$primary-color;
}

.heading-primary-a{
	@extend .heading-primary;
	@include add-border(2px, $primary-color, bottom);
}

.heading-secondary{
	color:$secondary-color;
}

.heading-secondary-a{
	@extend .heading-secondary;
	@include add-border(2px, $secondary-color, bottom);
}

.heading-light{
	color:$light-color;
}

.heading-light-a{
	@extend .heading-light;
	@include add-border(2px, $light-color, bottom);
}

.heading-dark{
	color:$dark-color;
}

.heading-dark-a{
	@extend .heading-dark;
	@include add-border(2px, $dark-color, bottom);
}

/* Emphasis Text */

.em-primary{
	color:$primary-color;
}

.em-secondary{
	color:$secondary-color;
}

.em-dark{
	color:$dark-color;
}

.em-light{
	color:$light-color;
}