/* Footer */

.footer-main{
    background: #004596 none repeat scroll 0 0;
    min-height: 73px;
    position: relative;
    z-index: -999;

    a{
        color:#ffffff;
    }

    ul{
        margin:0;
        padding:0;
    }

    li{
        line-height:1.8em;
        list-style:none;
    }

    p{padding-top: 31px;color: #7fadd5;}
}

.common-page-footer
{
    p
    {
        padding-top: 21px;
    }
}
