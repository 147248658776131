
/* Navbar */

.navbar{
    /*        width:100%;
            margin:auto;
            @include add-border(1px, #e7e7e7, 'right-left');
            border-color:#e7e7e7;
            min-height:60px;
            background:#fff;
            margin-bottom:30px;*/
    margin-bottom:10px;

    a{
        color: $color-white;
        /*font-size:20px;*/
        /*                letter-spacing:-0.5px;
                        padding-bottom:24px !important;
                        padding-top:20px !important;*/
    }

}

.navbar-inverse 
{
    background: #3a75c5 !important;
    color:#fff !important;
    border-color: transparent;

    a

    {
        color: $color-white ;
    }
}


.navbar{border-radius: 0;}

.navbar-inverse {

    background-color: #3a75c5; 

    li
    {
        border-right: 1px solid graytext ;
    }

    li:last-child{border-right: 0px;}

    a img
    {
        height: 20px;
    }

    .navbar-brand {
        color: #ecf0f1;
    }
    .navbar-brand:hover, .navbar-brand:focus {
        color: #ecdbff;
    }
    .navbar-text {
        color: #ecf0f1;
    }
    .navbar-nav > li > a {
        color: #ecf0f1;

        padding-bottom: 15px;
        padding-top: 15px;
        padding:14px 10px;
        font-size: 14px;

    }
    .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
        color: #ecdbff;
    }
    .navbar-nav > .active > a, .navbar-nav > .active > a:hover, .navbar-nav > .active > a:focus {
        color: #ecdbff;
        background-color: #164687;
    }

    .navbar-nav > .open > a, .navbar-nav > .open > a:hover, .navbar-nav > .open > a:focus {
        color: #ecdbff;
        background-color: #164687;
    }

    .navbar-toggle {
        border-color: #164687;
    }
    .navbar-toggle:hover, .navbar-toggle:focus {
        background-color: #164687;
    }
    .navbar-inverse .navbar-toggle .icon-bar {
        background-color: #ecf0f1;
    }

    .navbar-collapse, .navbar-form {
        border-color: #ecf0f1;
    }

    .navbar-link {
        color: #ecf0f1;
    }

    .navbar-link:hover {
        color: #ecdbff;
    }
}
@media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
        color: #ecf0f1;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #ecdbff;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #ecdbff;
        background-color: #164687;
    }
}