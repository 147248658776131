 /* Searchbox */
 
 .search{
	width:100%;
	
	h3{
		margin-top:0;
		padding-top:0;
	}
	
	input[type="search"]{
		border:0;
		height:50px;
		width:80%;
		border:2px solid $primary-color;
		font-size:18px;
		padding-left:10px;
		padding-bottom:5px;
	}
	
	button{
		border:0;
		background:none;
		padding:0;
		vertical-align:top;
		margin-left:-4px;
	}
 }
