.header_bg
{
    background-color: #00a592;
}


.affix {
    top: 0;
    width: 100%;
    z-index: 9999 !important;
}

.affix + .container-fluid {
    padding-top: 70px;
}


.header_info
{
    hr{margin: 0;border-color: #009380;}
    ul
    {
        padding: 3px 0 11px 0;
        li
        {
            color: $blue-color;
            text-align: right;
            font-weight: $bold;
            font-size: 20px;

        }
        li:first-child
            {
            color: $offwhite-color;   
            font-size: 18px;
            font-weight: $bold;
            font-style:  italic;
        }
    }
}

.logo
{
    padding-top: 20px;
}