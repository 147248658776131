/* Font Colors */

@font-face {
    font-family: 'noto_sans_bengaliregular';
    src: url('fonts/noto_sans_bengali-webfont.woff2') format('woff2'),
         url('fonts/noto_sans_bengali-webfont.woff') format('woff'),
         url('fonts/noto_sans_bengali-webfont.ttf') format('truetype'),
         url('fonts/noto_sans_bengali-webfont.svg#noto_sans_bengaliregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


$base-font-size:15px;
$base-font-family: 'noto_sans_bengaliregular', sans-serif;
$base-font-color:#666666;


/* Background Colors */

$primary-color:#00aff0;
$secondary-color:#7fba00;
$light-color:#e4eef2;
$dark-color:#2b5464;

$blue-color: #333399 ;
$offwhite-color: #ffccff;
$color-white: #fff;
$color-red: #ed1c24;
$site-grey-color: #999999;
$footer-bg: #004596;


/* Showcase Area */

$showcase-height:400px;
$showcase-image:'../img/bg.png';