@media only screen and (max-width: 1920px) {
    .footer-right-img{top: 94%;}

}

@media only screen and (max-width: 1440px) {

    .footer-right-img{top: 75%;}
}


@media only screen and (max-width: 1366px) {

    /*    .section-over{height: auto;}
        .footer-right-img{display: none;}*/
    .footer-right-img{top: 75%;}

}

@media only screen and (max-width: 1280px) {

    .footer-right-img{top: 117%;}
}


@media only screen and (max-width: 1024px) {

    /*    .section-over{height: auto;}
        .footer-right-img{display: none;}*/
    .section-over .notice-section .list-board ul li{line-height: 18px;}
    .section-over .notice-section .list-board ul li span{padding-left: 0px;top: -7px;}
    .section-over{height: 622px;}
}

@media only screen and (max-width: 991px) {
    .section-over{height: auto;}
    .footer-right-img{display: none;}
}

@media only screen and (max-width: 767px) {
    .search{
        display:inline-block;
        text-align:center;
    }

    .device{
        display:block;
        margin:20px auto 0 auto;
    }

    .device-small{
        margin-bottom:20px !important;
    }

    .footer-main{
        ul{
            margin-bottom:20px;
            text-align:center;
        }

        h4{
            text-align:center;
        }
    }
    .section-over{height: auto;}
    .footer-right-img{display: none;}
}

@media only screen and (max-width: 480px) 
{
    .header_info
    {
        ul
        {
            margin-top: 0;
            padding-top: 24px;
            text-align: center;

            li
            {
                text-align: center;
            }
        }
    }
    .section-banner .carousel-caption h3{font-size: 14px;}
    .section-banner .carousel-caption p{font-size: 11px;line-height: 16px;}
    h2,p{padding: 0;margin: 0;}
    .section-over{height: auto;}
    .footer-right-img{display: none;}

    .heading, .section-over .notice-section .list-board,   .messagebox p {
        padding: 0; }

 }
