/* Sections */

.container
{
    ​position: relative;
}

section{
    /*padding:6px 0;*/
    margin-top: 6px;
    margin-bottom: 6px;
}

.heading
{
    background: $color-white;
    padding:10px 0 1px 32px;
    h2
    {
        color: $blue-color;
        font-size: 18px;
    }
}

/*===================================*/

.section-banner
{
    .carousel
    {
        img
        {
            padding: 9px;background: $color-white;
        }
        
        .carousel-caption
        {
            img
            {
                background: transparent;
            }
        }
    }

    .carousel-caption
    {
        background: rgba(0, 0, 0, 0.4) none repeat scroll 0 0;
        bottom: 0;
        color: #fff;
        left: 0;
        padding-bottom: 0;
        padding-left: 19px;
        padding-top: 0;
        position: absolute;
        right: 0;
        text-align: left;
        z-index: 10;

        img 
        {
            border:0;padding:10px 0 0 10px;
        }        
        .col-md-1{width: 4%;}
        h3{font-weight: $bold;color: $color-red;font-size: 27px;margin: 0;}
        p{color: $site-grey-color;}
    }

}


/* Makes images fully responsive */

.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
    display: block;
    width: 100%;
    height: auto;
}

/* ------------------- Carousel Styling ------------------- */

.carousel-inner {
    /*border-radius: 15px;*/
}

.carousel-caption {
/*    background-color: rgba(0,0,0,.5);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding: 0 0 10px 25px;
    color: #fff;
    text-align: left;*/
}

.carousel-indicators {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 15;
    margin: 0;
    padding: 0 25px 25px 0;
    text-align: right;
}

.carousel-control.left,
.carousel-control.right {
    background-image: none;
}


/* ------------------- Section Styling - Not needed for carousel styling ------------------- */

/*.section-white {
    padding: 10px 0;
}

.section-white {
    background-color: #fff;
    color: #555;
}

@media screen and (min-width: 768px) {

    .section-white {
        padding: 1.5em 0;
    }

}
*/




/*=====================*/

.messagebox
{

    /*    .heading
        {
            background: $color-white;
            padding:10px 0 1px 32px;
            h2
            {
                color: $blue-color;
                font-size: 18px;
            }
        }*/
    p
    {

        background: $color-white;padding:10px 0 12px 32px;margin-top: 7px;

    }
}

/*==========================*/

.section-over
{
    margin-bottom: 6px;
    margin-top: 6px;
    height: 533px;
    position: relative;
    z-index: 999;

    .notice-section {

        .list-board
        {
            background: $color-white;
            padding:10px 0 1px 32px;
            margin-top: 2px;
            ul {
                li
                {
                    border-bottom: 1px solid #f2f2f2;

                    a
                    {
                        color: $blue-color;font-size: 12px;
                    }

                    span
                    {
                        color: #ab1117;
                        font-size: 11px;
                        padding-left: 20px;
                        padding-top: 0;
                        position: relative;
                        top: -9px;
                    }
                }
            }
            .btn-more
            {
                padding: 10px 0px;color: $site-grey-color;font-size: 11px;
            }


            .title-widget-sidebar {
                font-size: 14pt;
                border-bottom: 2px solid #e5ebef;
                margin-bottom: 15px;
                padding-bottom: 10px;    
                margin-top: 0px;
            }

            .title-widget-sidebar:after {
                border-bottom: 2px solid #f1c40f;
                width: 150px;
                display: block;
                position: absolute;
                content: '';
                padding-bottom: 10px;
            }

            .recent-post{list-style-type: none;
                         padding: 5px 0 0;
                         width: 100%;}
            .post-img img {
                width: 100px;
                height: 70px;
                float: left;
                margin-right: 15px;
            }

            .recent-post a {text-decoration: none;color:#34495E;transition: 0.5s;}
            .info-list
            {

                li{
                    padding: 14px 0 14px 0;
                    a {font-size: 19px;}
                }
            }

        }
    }
}

/*=============*/

.foo_other_links
{

    .list-brand
    {
        background: #fff;
        margin-top:7px;
        ul
        {
            li
            {
                padding-right: 24px;
                padding-left: 31px;
            }
        }
    }
}

.footer-right-img
{
    bottom: 0;
    position: absolute;
    right: 0;
    z-index: -999;
    top: 93%;
}

/*==============================================================*/

/*landing page ends*/

/*Common page starts*/