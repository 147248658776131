/*
	Jahangir Nagar University | Registered Graduate Cellp Sass Theme
	Author: Shoumitra Dhar Sunny Email: suned_p@yahoo.com , RootsoftIT Sylhet.
	Version: 1.0.0
*/

/* BASE - Base styles, variables, mixins, etc */

@import 'base/_variables.scss';
@import 'base/_mixins.scss';
@import 'base/_normalize.scss';
@import 'base/_base.scss';

/* MODULES - Individual site components */

@import 'modules/_typography.scss';
@import 'modules/_blocks.scss';
@import 'modules/_buttons.scss';
@import 'modules/_sections.scss';
@import 'modules/_components.scss';
@import 'modules/_forms.scss';

/* LAYOUTS - Page layout styles */

@import 'layouts/_nav.scss';
@import 'layouts/_header.scss';
@import 'layouts/_footer.scss';
@import 'layouts/_blog.scss';
@import 'layouts/_mobile.scss';