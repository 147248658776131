h1,h2,h3,h4,h5{
	margin-top:0;
}

ul{margin: 0;padding: 0;}

.page-header{
	margin-top:30px;
}

.no-pad-top{
	padding-top:0;
}

.extra-pad{
	padding-top:40px;
	padding-bottom:40px;
}

.top-inner{
	padding:90px 0 30px 0;
}

/* Image Sizes */
.img-xsm{width:100px;}
.img-sm{width:200px;}
.img-lg{width:400px;}
.img-xlg{width:700px;}

.clearfix{
	clear:both;
}